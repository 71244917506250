import axios from "axios";
import { REACT_APP_UNAUTHORIZED_REDIRECT } from "../constants";

export const headers = (token, sessionToken) => {
  if (token) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": token,
      // ...(sessionToken && {'x-api-key': sessionToken}), // For connectives
      ...(sessionToken && { "x-api-key": sessionToken }), // For connectives
    };
  }

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
};

export const getNewAccessToken = async (token) => {
  try {
    // Call Refresh token API
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/user/refresh-token`,
      method: "POST",
      headers: headers(token),
      data: {},
    });

    return response?.data?.data;
  } catch (error) {
    // If error on refresh token API
    localStorage.setItem("tokenExpired", true);
    localStorage.removeItem("token");
    // window.location.reload();
    window.location.replace(REACT_APP_UNAUTHORIZED_REDIRECT);
  }
};

export const reusableFetch = async (url, method, data, rejectWithValue, isArrayBuffer) => {
  const token = localStorage.token;
  const sessionToken = sessionStorage.token;
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/${url}`,
      method: method,
      responseType: isArrayBuffer ? "arraybuffer" : "json",
      headers: headers(token, sessionToken),
      data: data ? data : {},
    });
    return response; // Assuming the relevant data is returned from the API
  } catch (error) {
    // if (error.response.status === 404) {
    //   const origin = window.location.origin;
    //   console.log(origin);
    //    window.location.replace(`${origin}/404`);
    // }

    if (error.response.status === 401) {
      const newToken = await getNewAccessToken(token);

      if (newToken?.token) {
        localStorage.setItem("token", newToken.token);
        // reload page to call API Again otherwise user will freeze on API
        window.location.reload();
        return;
      }

      // localStorage.setItem("tokenExpired", true);
      // localStorage.removeItem("token");
      // // window.location.reload();
      // window.location.replace(REACT_APP_UNAUTHORIZED_REDIRECT);
    }
    // if (error.response.status === 403) {
    //  window.location.replace(`${origin}/404`);
    //   // window.history.back();

    // }
    return rejectWithValue(error.response ? error.response.data : error);
  }
};

export const reusableWithoutTokenFetch = async (url, method, data, rejectWithValue) => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/${url}`,
      method: method,
      headers: headers(),
      data: data ? data : "",
    });
    return response; // Assuming the relevant data is returned from the API
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.setItem("tokenExpired", true);
      localStorage.removeItem("token");
      // window.location.reload();
      // window.location.replace(REACT_APP_UNAUTHORIZED_REDIRECT);
    }
    // if (error.response.status === 403) {
    //     window.location.replace(`${origin}/404`);
    // }
    return rejectWithValue(error.response ? error.response.data : error);
  }
};

export const reusableFormDataFetch = async (url, method, formData, rejectWithValue) => {
  const token = localStorage.token;
  const formDataHeaders = headers(token);
  formDataHeaders["Content-Type"] = `multipart/form-data; boundary=${formData._boundary}`;
  delete formDataHeaders.Accept;

  try {
    if (formData) {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/${url}`,
        method: method,
        headers: formDataHeaders,
        data: formData,
      });
      return response; // Assuming the relevant data is returned from the API
    }
  } catch (error) {
    if (error.response.status === 401) {
      const newToken = await getNewAccessToken(token);

      if (newToken?.token) {
        localStorage.setItem("token", newToken.token);
        // reload page to call API again otherwise user will freeze on API/Page
        window.location.reload();
        return;
      }
    }
    // if (error.response.status === 403) {
    //   window.location.replace(`${origin}/404`);
    //   // window.history.back();
    // }
    return rejectWithValue(error.response ? error.response.data : error);
  }
};

export const getUserInfo = async ({ access_token }) => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_CONNECTIVE_USER_INFO_URL}`,
      method: "GET",
      headers: headers(access_token),
      data: "",
    });
    console.log("getUserInfo_response ::", response);

    return response;
  } catch (error) {
    console.log("getUserInfo_error ::", error);
    if (error.response.status === 401) {
      // localStorage.setItem("tokenExpired", true);
      // localStorage.removeItem("token");
      // window.location.replace(REACT_APP_UNAUTHORIZED_REDIRECT);

      const newToken = await getNewAccessToken(access_token);

      if (newToken?.token) {
        localStorage.setItem("token", newToken.token);
        // reload page to call API Again otherwise user will freeze on API
        window.location.reload();
        return;
      }
    }
  }
};
