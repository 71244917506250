import React, { useRef, useState } from "react";
// import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardActionArea, Grid, Stack, Typography } from "@mui/material";
// import UploadFileIcon from "@mui/icons-material/UploadFile";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import Document from "./Document";
import CustomCardSending from "./CustomCardSending";
import {
  // getDocuments,
  saveValue,
  sendPrivacyLink,
  uploadDocument,
  userDetailsSelector,
} from "../../store/slices/applicationFormSlice";
import { ALLOWED_DOCUMENT_TYPE, ERRORS_MESSAGE, MAX_DOCUMENT_UPLOAD_SIZE, PLATFORM_NAME, REACT_APP_PLATFORM_NAME, SUCCESS_MESSAGE, VALID_DOCUMENT_TYPE_EXTENSION } from "../../constants";
// import { userSelector } from "../../store/slices/userSlice";
import { HtmlTooltip } from "../ProductSelector/Product";

const CustomerPrivacyAgreement = React.memo(({ customer, applicationId, isCustomerLogin }) => {
  const dispatch = useDispatch();
  // Do not show privacy link btn to only connective
  const isShowPrivacyLink = REACT_APP_PLATFORM_NAME === PLATFORM_NAME.CONNECTIVE ? !isCustomerLogin : true
  const isPrivacyDisabled = !customer?.phoneNumbers?.[0]?.number 
  || !customer?.emails?.[0]?.address 
  || !customer?.firstName 
  || !customer?.lastName 
  
  const { customerPrivacyRequest, uploadPrivacyLoading } = useSelector(userDetailsSelector);
  // const userDetails = useSelector(userSelector);

  const fileInput = useRef();
  const [error, setError] = useState("");


  const Input = styled("input")({
    display: "none",
  });

  // const [uploadPrivacyButtonBackground, setUploadPrivacyButtonBackground] = useState("secondary");
  // const [uploadPrivacyButtonText, setUploadPrivacyButtonText] = useState("Upload Privacy Agreement");

  // const [privacyButtonBackground, setPrivacyButtonBackground] = useState("secondary");
  // const [privacyButtonText, setPrivacyButtonText] = useState("Send privacy link");

  // const uploadPrivacyLoading = "";
  const privacyLoading = "";
  const { enqueueSnackbar } = useSnackbar();
  // const [buttonPrivacyColorText, setButtonPrivacyColorText] = useState({
  //   buttonPrivacyBackground: "secondary",
  //   buttonPrivacyText: "Send privacy link",
  // });

  // const [buttonUploadPrivacyColorText, setButtonUploadPrivacyColorText] = useState({
  //   buttonUploadPrivacyBackground: "secondary",
  //   buttonUploadPrivacyText: "Upload Privacy Agreement",
  // });

  // const [delay, setDelay] = useState(null);

  const handle = {
    uploadPrivacy: (event, customerId) => {
      const files = event.target.files;
      const newSelectedFiles = [];
      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        console.log("file", file.type)
        if (ALLOWED_DOCUMENT_TYPE.includes(file.type)) {
          if (file.size <= MAX_DOCUMENT_UPLOAD_SIZE) {
            newSelectedFiles.push(file);
          } else {
            setError(ERRORS_MESSAGE.documentSizeMsg);
          }
        } else {
          setError(ERRORS_MESSAGE.documentTypeMsg);
        }
      }
      if (newSelectedFiles?.length) {
        dispatch(saveValue({ uploadPrivacyLoading: true }));
        newSelectedFiles.forEach((file) => {
          formData.append("file", file);
        });
        // formData.append("file", event.target.files[0]);
        formData.append("applicationId", applicationId);
        formData.append("customerId", customerId);
        formData.append("name", "Privacy agreement");

        dispatch(uploadDocument(formData)).then((res) => {
          if (res?.payload?.data) {
            enqueueSnackbar(SUCCESS_MESSAGE.fileUploadSuccess, {
              variant: "success",
              autoHideDuration: 3000,
            });
          } else {
            enqueueSnackbar(ERRORS_MESSAGE.fetchErrorMsg, {
              variant: "error",
              autoHideDuration: 3000,
            });
          }
        });
        event.target.input = null;
        setError("");
      }
      // =====================================================================================
      // Request made to the backend api
      // Send formData object
    },
    // uploadPrivacyButtonSuccessStyle: () => {
    // setButtonUploadPrivacyColorText({
    //   buttonUploadPrivacyBackground: "success",
    //   buttonUploadPrivacyText: "Successfully uploaded",
    // });
    // setTimeout(() => {
    //   setButtonUploadPrivacyColorText({
    //     buttonUploadPrivacyBackground: "secondary",
    //     buttonUploadPrivacyText: "Upload Privacy Agreement",
    //   });
    //   dispatch(
    //     getDocuments(
    //       customer._id,
    // application: applicationId,
    //     ),
    //   );
    // }, 6000);
    // },
    handleSendPrivacyLink(customerId, applicationId, mobile) {
      // setDelay(30000);
      dispatch(saveValue({ privacyLoading: true }));
      dispatch(sendPrivacyLink({ customerId, applicationId, mobile })).then((res) => {
        if (!res?.payload?.response_error) {
          enqueueSnackbar(SUCCESS_MESSAGE.privacy, {
            variant: "success",
            autoHideDuration: 5000,
          });
          // handle.privacyButtonSuccessStyle();
        } else {
          enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      });
    },
    // privacyButtonSuccessStyle: () => {
    //   setButtonPrivacyColorText({
    //     buttonPrivacyBackground: "success",
    //     buttonPrivacyText: "link sent",
    //   });
    //   setTimeout(() => {
    //     setButtonPrivacyColorText({
    //       buttonPrivacyBackground: "secondary",
    //       buttonPrivacyText: "Send privacy link",
    //     });
    //   }, 6000);
    // },
  };

  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        style={{
          margin: "0 0 30px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          paddingBottom: "30px",
        }}
      >
        <Typography id="input-slider" fontWeight={600} style={{ marginBottom: "10px" }}>
          Privacy Agreement
        </Typography>
        <Stack
          spacing={1}
          direction="row"
          style={{ margin: "0 0 20px" }}
        // divider={<Divider orientation="vertical" flexItem />}
        >
          {customer?.documents?.length > 0 &&
            customer?.documents
              ?.filter((doc) => doc.name === "Privacy agreement")
              .map((doc) => <Document document={doc} customer={customer} name="privacyAgreement" />)}
        </Stack>

        <Grid container item spacing={2}>
          {isShowPrivacyLink &&
            (<Grid item xs={12} sm={6}>
              <HtmlTooltip
                tooltipPadding={"20px"}
              // title={
              //   !userDetails?.is_service_enable && (
              //     <React.Fragment>
              //       <Typography color="inherit" marginBottom="5px">
              //         {creditCardModalInfo.mainTitle}.
              //       </Typography>
              //       <Typography color="inherit" fontSize="14px" marginBottom="10px">
              //         {creditCardModalInfo.subTitle}
              //       </Typography>
              //       <Link to="/billing/creditCard">
              //         <Button variant="outlined" size="small">
              //           {creditCardModalInfo.btnText}
              //         </Button>
              //       </Link>
              //     </React.Fragment>
              //   )
              // }
              >
                <Card
                  onClick={() => {
                    if (isPrivacyDisabled ? true : false) return;
                    handle.handleSendPrivacyLink(customer._id, applicationId, customer?.phoneNumbers.slice(-1)[0]);
                    // dispatch(saveValue({ linkSent: true }));
                    // setDelay(30000);
                  }}
                >
                  <CardActionArea
                    disableRipple={
                      // !customer?.phoneNumbers?.[0]?.number 
                      isPrivacyDisabled
                        ? true
                        : false}
                  >
                    <CustomCardSending
                      title={"Get signed Privacy"}
                      description={`Send a self service link to digitally
              sign the privacy and consent statement.`}
                      imagePath={""}
                      loadingState={privacyLoading}
                      financialState={true}
                      icon={false}
                      // disabled={!customer?.phoneNumbers?.[0]?.number ? true : false}
                      disabled={isPrivacyDisabled ? true : false}
                      lastTitle={"Sending SMS link..."}
                    />
                  </CardActionArea>
                </Card>


                {isPrivacyDisabled && (
                  <Typography variant="caption">
                    Sending a privacy link requires a customer {!customer?.firstName && 'first name, '} {!customer?.lastName && 'last name, '} {!customer?.emails?.[0]?.address && 'email, '} {!customer?.phoneNumbers?.[0]?.number && 'mobile number'}
                  </Typography>
                )}
                {customerPrivacyRequest && <Typography variant="caption">{SUCCESS_MESSAGE.privacy}</Typography>}
              </HtmlTooltip>
            </Grid>
            )}

          <Grid item xs={12} sm={6}>
            <Card>
              <Input
                accept={VALID_DOCUMENT_TYPE_EXTENSION}
                ref={fileInput}
                id={`upload-id-${customer._id}`}
                multiple
                type="file"
                value={null}
                onChange={(event) => handle.uploadPrivacy(event, customer._id)}
              />
              <CardActionArea onClick={() => fileInput.current.click()}>
                <CustomCardSending
                  title={"Upload sign privacy"}
                  description={`  Upload a signed privacy statement from files stored locally on
                your computer.`}
                  imagePath={""}
                  loadingState={uploadPrivacyLoading}
                  financialState={true}
                  lastTitle={"Upload privacy..."}
                />
              </CardActionArea>
            </Card>
            {error && (
              <Typography variant="body2" style={{ color: "red" }}>
                {error}
              </Typography>
            )}
          </Grid>

        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default CustomerPrivacyAgreement;
