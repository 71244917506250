import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const ConfirmModal = ({ isOpen, title, message, setIsOpen, handle, btnTextYes, btnTextNo }) => {
  const closeDialog = () => {
      handle({ ...isOpen?.roleInfo, isSave: false });
    setIsOpen(false);
  };
  const confirmation = () => {
    handle({ ...isOpen?.roleInfo, isSave: true });
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen || isOpen?.isOpen} fullWidth maxWidth={"sm"}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} variant="outlined">
          {btnTextNo || "Cancel"}
        </Button>
        <Button onClick={confirmation} variant="outlined">
          {btnTextYes || "Okay"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
