import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import PercentIcon from "@mui/icons-material/Percent";
import * as Yup from "yup";
// import RadioButtonGroup from "../Utils/RadioButtonGroup";
// import CloseIcon from "@mui/icons-material/Close";
import calculateEffectiveRate from "../../utils/calculateEffectiveRate";
// import calculateComparisonRate from "../../utils/calculateComparisonRate";
import {
  assetSelector,
  entitySelector,
  loanDetailsSelector,
  updateLoanDetails,
} from "../../store/slices/applicationFormSlice";
import calculateRepayments from "../../utils/calculateRepayments";
import getMonthlyRate from "../../utils/getMonthlyRate";
import { APPLICATION_TYPES, typesOfFees } from "../../constants";
import { PercentageFormatCustom, percentageMaskedValue } from "../../utils/percentageMaskFormat";
import regex from "../../utils/regex";
import { NumericFormatCustom, currencyMaskedValue } from "../../utils/currencyMaskFormat";
import { getStringTitleCase } from "../Utils/helperFunction";

const defaultQuoteFeesList = [
  {
    // _id: 1,
    key: "originationFee",
    name: "Origination fee",
    // frequency: "originationfeeFinanced", // capitalised
    value: "",
    capitalised: false,
    frequency: "",
  },
  {
    // _id: 2,
    key: "documentFee",
    name: "Document fee",
    // frequency: "documentFeeFinanced",
    value: "",
    capitalised: false,
    frequency: "",
  },
  {
    // _id: 3,
    key: "establishmentFee",
    name: "Establishment fee",
    // frequency: "establishmentFeeFinanced",
    value: "",
    capitalised: false,
    frequency: "",
  },
  {
    // _id: 4,
    key: "monthlyFee",
    name: "Monthly fee",
    // frequency: "monthlyFeeFinanced",
    value: "",
    capitalised: false,
    frequency: "",
  },
];

const frequencyList = [
  { label: "Upfront", value: "Upfront" },
  { label: "Financed", value: "Financed" },
  { label: "Monthly", value: "Monthly" },
];

const InArrearsTypesList = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

const QuoteCalculator = React.memo(({ evaluatedQuoteFees, setEvaluatedQuoteFees, application }) => {
  const dispatch = useDispatch();
  const {
    assetValue = 0,
    ageOfAsset = "",
    assetType = "",
    typeOfSale,
    glassesMake,
    glassesModel,
    glassesVariant,
  } = useSelector(assetSelector);

  const { balloon = 0, term = 0, tradeIn = 0, payout = 0, deposit = 0 } = useSelector(loanDetailsSelector);

  const entity = useSelector(entitySelector);
  const loanDetails = application?.loanDetails;

  const [quoteCalculatorField, setQuoteCalculatorField] = useState({
    rate: loanDetails?.rate || 0,
    inArrears: loanDetails?.inArrears || "Yes",
    brokerage: loanDetails?.brokerage || "",
    fees: loanDetails?.fees?.length > 0 ? loanDetails?.fees : defaultQuoteFeesList,
  });
  const [fieldName, setFieldName] = useState("");
  const [fieldsList, setFieldList] = useState([]);

  const [quoteFieldErrors, setQuoteFieldErrors] = useState({
    rate: "",
    brokerage: "",
    inArrears: "",
  });

  const validationSchema = Yup.object({
    rate: Yup.string()
      .required("Rate is required")
      .matches(regex.percentageRegex, "Please enter valid percentage.")
      .matches(regex.percentageRegex, {
        message: "Percentage should be upto 100%.",
      }),
    brokerage: Yup.string()
      .required("Brokerage is required")
      .matches(regex.percentageRegex, "Please enter valid percentage.")
      .matches(regex.percentageRegex, {
        message: "Percentage should be upto 100%.",
      }),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setQuoteFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: "",
      }));
    } catch (error) {
      setQuoteFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  useEffect(() => {
    const array2Fees = quoteCalculatorField?.fees?.map((item) => item.key);
    const newArray1 = typesOfFees?.filter((item) => !array2Fees.includes(item));
    setFieldList(newArray1);
  }, [quoteCalculatorField]);

  // useEffect(() => {
  //   if (application?.loanDetails?.fees?.length > 0) {
  //     setQuoteCalculatorField({
  //       // ...quoteCalculatorField,
  //       rate: application?.loanDetails?.rate,
  //       brokerage: application?.loanDetails?.brokerage,
  //       fees: application?.loanDetails?.fees,
  //       inArrears: application?.loanDetails?.inArrears,
  //     });
  //     return;
  //   }
  // }, [application]);

  const handle = {
    setFieldName: (value) => {
      setFieldName(value);
    },
    onChangeField: async (e, name, key) => {
      // let isValid = true;
      let updateField = [];
      if (name === "value") {
        updateField = quoteCalculatorField?.fees?.map((obj) => {
          if (obj.key === key) {
            return { ...obj, [name]: e };
          }
          return obj;
        });
        setQuoteCalculatorField({
          ...quoteCalculatorField,
          fees: updateField,
        });
        return;
      }

      // if (name === "rate" || name === "brokerage") {
      // const splitString = e?.split(".");
      // isValid =
      //   (e === "" || regex.allowOnlyNumber.test(e)) && e?.length <= 10 && splitString[1]?.length
      //     ? splitString[1]?.length <= 2
      //     : e[7] !== "."
      //       ? e?.length <= 7
      //       : e?.length <= 10;
      // }
      if (name !== "rate") {
        setQuoteCalculatorField({
          ...quoteCalculatorField,
          [name]: e,
        });
      }
      await validateField(name, e);
    },
    onChangeRadio: (value, name, key) => {
      if (key) {
        let updateField = [];
        updateField = quoteCalculatorField?.fees?.map((obj) => {
          if (obj.key === key) {
            return { ...obj, [name]: value, capitalised: name === "frequency" && value === "Financed" ? true : false };
          }
          return obj;
        });
        setQuoteCalculatorField({
          ...quoteCalculatorField,
          fees: updateField,
        });

        return;
      } else {
        setQuoteCalculatorField({ ...quoteCalculatorField, [name]: value });
        return;
      }
    },
    blurFn: (fieldName, value, index) => {
      try {
        let fieldErr = quoteFieldErrors[fieldName];

        setQuoteFieldErrors((pre) => ({
          ...pre,
          [fieldName]: fieldErr,
        }));

        if (fieldName === "rate") {
          setQuoteCalculatorField({
            ...quoteCalculatorField,
            rate: +value,
          });
        }

        if (!fieldErr || fieldErr === "") {
          const dataToBeSave = {
            rate: +quoteCalculatorField?.rate,
            brokerage: +quoteCalculatorField?.brokerage,
            brokerageAmount: +evaluatedQuoteFees?.brokerageAmount,
            loanAmount: +evaluatedQuoteFees?.loanAmount,
            fees: quoteCalculatorField?.fees,
            inArrears: quoteCalculatorField?.inArrears,
          };
          dispatch(updateLoanDetails({ _id: loanDetails?._id, ...dataToBeSave }));
        }
      } catch (error) {
        const newErrors = {};
        error?.inner?.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setQuoteFieldErrors(newErrors);
      }
    },
    // Handle function for calculate fees
    evaluateQuotes: () => {
      // ========================================================================================
      const quoteFeesList = quoteCalculatorField?.fees;

      const newLoanDetails = {
        loanAmount: assetValue - deposit,
        term,
        deposit: +deposit,
        balloon,
      };

      const feesList = quoteFeesList.map((fee) => {
        if (fee.calc === "percent") {
          const feeValue = +fee.value * 1;
          const newFeeValue = (feeValue / 100) * newLoanDetails.loanAmount;
          return { ...fee, value: newFeeValue };
        }
        return { ...fee, value: +fee.value };
      });

      const monthlyFees = feesList.find((fee) => fee.key === "monthlyFee");

      const feesTotal = feesList.reduce((accum, fee) => {
        let feeValue = fee.value * 1;
        if (fee.percentage) {
          return (fee.value / 100) * newLoanDetails.loanAmount + accum;
        }
        // if (fee.name === "Origination fee") {
        //   feeValue = originationFee < fee.value ? parseFloat(originationFee) : fee.value;
        // }
        if (fee.frequency === "monthly") return accum;
        if (fee.capitalised) return feeValue + accum;
        return accum;
      }, 0);

      const FinanceFeesTotal = feesList.reduce((accum, fee) => {
        // if (fee.percentage) {
        //   return (fee.value / 100) * newLoanDetails.loanAmount + accum;
        // }
        // if (fee.frequency === "monthly") return accum;
        // Upfront: false || Financed: true || Monthly: false
        if (fee.frequency === "Financed") return fee.value + accum;
        return accum;
      }, 0);

      const monthlyFeesTotal = feesList.reduce((accum, fee) => {
        // if (fee.frequency === "Monthly") return fee.value + accum;
        // Remove duplicate fees count
        if (fee.key !== "monthlyFee" && fee.frequency === "Monthly") {
          return fee.value + accum;
        }
        return accum;
      }, 0);

      const brokerage = quoteCalculatorField?.brokerage || 0;
      const isCommercial = application.applicationType === APPLICATION_TYPES.COMMERCIAL;

      const netAssetValue = assetValue * 1 - deposit * 1 - tradeIn * 1 + payout * 1;
      const adjustedBrokerage = +brokerage * 1;
      const finalBrokerage = adjustedBrokerage ? adjustedBrokerage : 0;
      const brokerageAmount = netAssetValue * (finalBrokerage / 100);

      let loanAmount = 0
      // const loanAmount =
      //   application?.applicationType === APPLICATION_TYPES.COMMERCIAL
      //     ? handle.loanAmountCommercial(FinanceFeesTotal, netAssetValue, brokerageAmount)
      //     : handle.loanAmountConsumer(FinanceFeesTotal, netAssetValue);

      switch (application?.applicationType) {
        case APPLICATION_TYPES.COMMERCIAL:
          loanAmount = handle.loanAmountCommercial(FinanceFeesTotal, netAssetValue, brokerageAmount)
          break;

        case APPLICATION_TYPES.CONSUMER:
          loanAmount = handle.loanAmountConsumer(FinanceFeesTotal, netAssetValue);
          break;

        case APPLICATION_TYPES.PERSONAL:
          loanAmount = FinanceFeesTotal + (+loanDetails?.loanAmount)
          break;

        default:
          break;
      }

      const rate = (+quoteCalculatorField?.rate) || 0;
      const quoteDetails = handle.calculateQuote(loanAmount, balloon, term, rate, netAssetValue);
      // const quoteWithNoBalloon = handle.calculateQuote(loanAmount, 0, term, rate, netAssetValue);

      // const balloonAmount = ((balloon / 100) * assetValue)
      const balloonAmount = ((balloon / 100) * netAssetValue)
      const presentValue = balloonAmount / Math.pow(1 + rate, term)
      const trueLoanAmount = loanAmount - presentValue;

      // const inArrearsValue = loanDetails?.inArrears === "Yes" ? 0 : 1 
      const inArrearsValue = quoteCalculatorField?.inArrears === "Yes" ? 0 : 1

      // const comparisonRateRaw = calculateComparisonRate(
      //   term,
      //   -quoteWithNoBalloon.repayments,
      //   // trueLoanAmount - FinanceFeesTotal,
      //   trueLoanAmount - feesTotal - (isCommercial ? brokerageAmount : 0),
      //   0, // (balloon / 100) * assetValue
      //   // 0,
      //   inArrearsValue, // quoteCalculatorField?.inArrears ? inArrearsValue : 0
      //   rate / 100,
      // );

      const comparisonRateRaw = calculateEffectiveRate(
        term,
        quoteDetails.repayments,
        -(trueLoanAmount - feesTotal - (isCommercial ? brokerageAmount : 0)),
        balloonAmount,
        inArrearsValue, // quoteCalculatorField?.inArrears ? inArrearsValue : 0,
        rate / 100,
      );

      // const effectiveRate = comparisonRateRaw * 12 * 100;
      // const comparisonRate = comparisonRateRaw * 12 * 100;
      const comparisonRate = comparisonRateRaw;
      // const compoundingYears = 12
      // const newRate = rate / 100
      // const compoundRate = newRate / compoundingYears
      // const effectiveRate = (Math.pow(1 + compoundRate, compoundingYears) - 1) * 100

      // Calculation verified on tvm-calculator package
      const effectiveRate = calculateEffectiveRate(
        term,
        quoteDetails.repayments,
        -(netAssetValue + FinanceFeesTotal),
        balloonAmount,
        inArrearsValue, //loanDetails?.inArrears ? inArrearsValue : 0
        (+rate / 100)
      );

      const finalRepayments = quoteDetails.repayments + (+monthlyFees?.value || 0) + monthlyFeesTotal;

      const financeAmount = loanAmount
      // application?.applicationType === APPLICATION_TYPES.PERSONAL ? loanAmount : netAssetValue;
      // application?.applicationType === APPLICATION_TYPES.PERSONAL ? loanDetails?.loanAmount : netAssetValue;

      const evaluatedQuotesDetails = {
        repayments: Number((+finalRepayments).toFixed(2)),
        financeAmount: Number((+financeAmount).toFixed(2)),
        // brokerage: Number(brokerageAmount.toFixed(2)), //finalBrokerage
        brokerageAmount: Number((+brokerageAmount).toFixed(2)),
        loanPayableAmount: Number(((+quoteDetails.repayments * term) + (+balloonAmount || 0)).toFixed(2)),
        // loanAmount: Number((+quoteDetails.repayments * term).toFixed(2)),
        loanAmount: application?.applicationType === APPLICATION_TYPES.PERSONAL
          ? (+loanDetails?.loanAmount)
          : Number((+quoteDetails.repayments * term).toFixed(2)),
        feesTotal: Number((+FinanceFeesTotal).toFixed(2)),
        comparisonRate: Number((+comparisonRate).toFixed(2)),
        effectiveRate: Number((+effectiveRate).toFixed(2)),
        quoteCalculatorField: quoteCalculatorField,
      };

      setEvaluatedQuoteFees(evaluatedQuotesDetails);
      // ==========================================================================================
    },
    calculateQuote: (loanAmount, balloon, term, rate, netAssetValue) => {
      const monthlyRate = getMonthlyRate(rate) || 0;
      const repayments = calculateRepayments(
        monthlyRate,
        term,
        -loanAmount,
        // (balloon / 100) * assetValue,
        (balloon / 100) * netAssetValue,
        quoteCalculatorField?.inArrears === "Yes" ? 0 : 1,
      );

      return {
        repayments: Number(repayments.toFixed(2)),
      };
    },
    loanAmountCommercial: (fees, netAssetValue, brokerageAmount) => (+netAssetValue) + (+brokerageAmount) + (+fees),
    loanAmountConsumer: (fees, netAssetValue) => (+netAssetValue) + (+fees),
  };

  useEffect(() => {
    handle.evaluateQuotes();
  }, [
    quoteCalculatorField,
    // customers,
    assetType,
    ageOfAsset,
    balloon,
    term,
    entity,
    // orderByParameters,
    // filterByParameters,
    // filterByProductType,
    // fitFilterValue,
    typeOfSale,
    // criteriaFilterValue,
    assetValue,
    deposit,
    tradeIn,
    payout,
    glassesMake,
    glassesModel,
    glassesVariant,
  ]);

  const formatFeeLabel = (value) => {
    if (!value) return "";
    const key = value.split("Fee");
    const label = getStringTitleCase(key[0]);

    return `${label} Fee`;
  };

  const onAddNewFee = () => {
    // Do not allow duplicate fees name
    if (fieldName) {
      const data = {
        // _id: Date.now(),
        key: fieldName,
        name: formatFeeLabel(fieldName),
        value: "",
        capitalised: false,
        frequency: "",
        // frequency: "monthlyFeeFinanced",
      };
      let newFees = [...quoteCalculatorField.fees, data];
      setQuoteCalculatorField({
        ...quoteCalculatorField,
        fees: newFees,
      });
      handle.setFieldName("");
    }
  };

  // const removeFee = (key) => {
  //   const arr = quoteCalculatorField?.fees?.filter((obj) => obj?.key !== key);
  //   // const newList = quoteCalculatorField.fees.push(data)
  //   setQuoteCalculatorField({
  //     ...quoteCalculatorField,
  //     fees: arr,
  //   });
  //   // return arr;
  // };

  return (
    <div style={{ borderBottom: "1px solid rgba(0,0,0,0.12)" }}>
      <Grid container spacing={1} sm={12} direction="row" style={{ margin: "10px 0px 7px 0px" }}>
        <Grid item sm={12} spacing={1}>
          <Typography
            variant="h5"
            style={{
              margin: "7px 0px 7px 0px",
            }}
            fontWeight={600}
          >
            Quote calculator
          </Typography>
        </Grid>
        <Grid container spacing={1} direction="row" style={{ margin: "0px 0px 0px 0px", alignItems: "start" }}>
          <Grid item sm={6}>
            <TextField
              id={"rate"}
              // fullWidth
              type="text"
              variant="filled"
              name="rate"
              label="Rate"
              size="small"
              // endAdornment={
              //   <InputAdornment position="end">
              //     <IconButton color="secondary">
              //       <PercentIcon fontSize="small" />
              //     </IconButton>
              //   </InputAdornment>
              // }
              value={quoteCalculatorField?.rate}
              error={quoteFieldErrors?.rate}
              helperText={quoteFieldErrors?.rate}
              InputProps={{
                inputComponent: PercentageFormatCustom,
              }}
              onChange={(event) => handle.onChangeField(event?.target?.value, "rate")}
              onBlur={(e) => handle.blurFn("rate", percentageMaskedValue(e.target.value))}
            />
          </Grid>
          <Grid item sm={6}>
            {/* <Typography variant="caption" fontWeight={"bold"}>
                In arreas
              </Typography>
              <RadioButtonGroup
                required
                name={"inArrears"}
                value={quoteCalculatorField?.inArrears}
                handleValue={(value) => {
                  handle.onChangeRadio(value, "inArrears");
                  // handle.handleResidencyStatus(value);
                }}
                options={[
                  { icon: "Yes", label: "Yes" },
                  { icon: "No", label: "No" },
                ]}
              /> */}
            <FormControl fullWidth variant="filled">
              <InputLabel id="arrears-label">In arrears</InputLabel>
              <Select
                size="small"
                labelId="arrears-label"
                id="arrears-select"
                value={quoteCalculatorField?.inArrears}
                label="In arrears"
                onChange={(event) => {
                  handle.onChangeRadio(event.target.value, "inArrears");
                }}
                onBlur={(event) => handle.blurFn("inArrears", event.target.value)}
              >
                {InArrearsTypesList.map((i) => (
                  <MenuItem value={i.value}>{i.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {application?.applicationType === APPLICATION_TYPES.COMMERCIAL && (
          <Grid container spacing={1} direction="row" style={{ margin: "0px 0px 7px 0px" }}>
            <Grid item sm={6} spacing={1}>
              <TextField
                style={{ marginTop: "7px" }}
                id={"brokerage"}
                // fullWidth
                type="text"
                variant="filled"
                name="brokerage"
                label="Brokerage"
                size="small"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton color="secondary">
                      <PercentIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                }
                value={quoteCalculatorField?.brokerage}
                error={quoteFieldErrors?.brokerage}
                helperText={quoteFieldErrors?.brokerage}
                InputProps={{
                  inputComponent: PercentageFormatCustom,
                }}
                onChange={(event) => handle.onChangeField(event?.target?.value, "brokerage")}
                onBlur={(event) => handle.blurFn("brokerage", percentageMaskedValue(event?.target?.value))}
              />
            </Grid>
          </Grid>
        )}

        {quoteCalculatorField?.fees?.map((quoteField, index) => (
          <Grid container spacing={1} direction="row" style={{ margin: "0px 0px 0px 0px" }} key={quoteField.key}>
            {/* <span>
                    <Typography variant="caption" fontWeight={"bold"}>
                        Financed{" "}
                      </Typography>
                    {quoteCalculatorField?.fees?.length > 2 &&
                      index > 2 && (
                        <CloseIcon
                          style={{
                            cursor: "pointer",
                            fontSize: "15px",
                            color: "red",
                            // marginTop: "10px"
                          }}
                          size="small"
                          onClick={() => removeFee(quoteField?.key)}
                          variant="outlined"
                        />
                      )}
                  </span> */}
            <Grid item sm={6} spacing={1}>
              {/* <Typography variant="caption" fontWeight={"bold"}>
                      {quoteField?.name}
                    </Typography> */}
              <TextField
                id={quoteField?.key}
                fullWidth
                type="text"
                variant="filled"
                // disabled={searchVehicleLoader}
                name={quoteField?.key}
                label={quoteField?.name}
                size="small"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                }}
                value={quoteField?.value}
                onChange={(event) => {
                  handle.onChangeField(event?.target?.value, `value`, quoteField.key);
                }}
                onBlur={(e) => handle.blurFn(`value`, currencyMaskedValue(e.target.value), quoteField.key)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton color="secondary">
                      <PercentIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item sm={6}>
              <FormControl fullWidth variant="filled">
                <InputLabel id="type-label">Type</InputLabel>
                <Select
                  size="small"
                  labelId="type-label"
                  id="type-label-select"
                  value={quoteField?.frequency}
                  label="Type"
                  onChange={(event) => {
                    handle.onChangeRadio(event.target.value, "frequency", quoteField.key);
                  }}
                  onBlur={(event) => handle.blurFn(`frequency`, event.target.value, quoteField.key)}
                >
                  {frequencyList.map((i) => (
                    <MenuItem value={i.value}>{i.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        ))}
      </Grid>

      {fieldsList?.length > 0 && (
        <Grid container spacing={1} direction="row" style={{ margin: "7px 0px 15px 0px", alignItems: "center" }}>
          {/* Use typesOfFees List from constant */}
          <Grid item sm={6} xs={6} spacing={1}>
            <FormControl fullWidth variant="filled">
              <InputLabel id="type-label">Select Fee</InputLabel>
              <Select
                size="small"
                labelId="type-label"
                id="type-label-select"
                value={fieldName}
                label="Type"
                onChange={(event) => {
                  handle.setFieldName(event.target.value);
                }}
              >
                {fieldsList?.map((i) => (
                  <MenuItem value={i}>{formatFeeLabel(i)}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={6}>
            <div>
              <Button disabled={!fieldName} size="small" onClick={() => onAddNewFee()} variant="outlined">
                Add Fee +
              </Button>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
});

export default QuoteCalculator;