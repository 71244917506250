import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, TextField } from "@mui/material";
import moment from "moment";
import * as Yup from "yup";
import { debounce } from "lodash";
import regex from "../../../utils/regex";
import { handleMixpanelQuoteEvent } from "../../../utils/mixPanelTrackEvents";
import {
  updateAsset,
  assetSelector,
  saveAsset,
  loanDetailsSelector,
  updateLoanDetails,
  saveLoanDetails,
} from "../../../store/slices/applicationFormSlice";

const Term = () => {
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const { ageOfAsset, _id: assetId } = useSelector(assetSelector);
  const { _id, term } = useSelector(loanDetailsSelector);
  const [termValue, setTermValue] = useState(term);

  const [fieldErrors, setFieldErrors] = useState({
    term: "",
  });

  const validationSchema = Yup.object({
    term: Yup.string()
      .optional()
      .matches(regex.termsRegex, {
        message: "Terms must be up to 84 months",
      })
      .max(2, "Maximum of 2 digits"),

  });

  const debouncedValidation = useCallback(
    debounce(async (fieldName, value) => {
      try {
        await validationSchema.validateAt(fieldName, { [fieldName]: value });
        setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
      } catch (error) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: error.message,
        }));
      }
    }, 300),
    []
  );

  const handle = {
    onChangeField: async (e, name) => {
      if (e === "") {
        setTermValue(e);
        debouncedValidation(name, e);
        return;
      }

      setTermValue(+e);
      debouncedValidation(name, e);
    },
    blurFn: async (fieldName, value) => {
      try {

        if (+value > 0 && +value <= 84 && fieldErrors[fieldName] === "") {
          handleMixpanelQuoteEvent(applicationId, "term", value)
          const termInYears = value / 12;
          const ageOfAssetMoment = moment(ageOfAsset, "YYYY");
          const endOfTermYear = moment().add(termInYears, "year");
          const ageOfAssetAtEnd = endOfTermYear.diff(ageOfAssetMoment, "years");
          dispatch(saveAsset({ ageOfAssetAtEnd }));
          dispatch(updateAsset({ _id: assetId, ageOfAssetAtEnd }));
          dispatch(saveLoanDetails({ term: value }))
          dispatch(updateLoanDetails({ _id, term: value }));
        }
      } catch (error) {
        const newErrors = {};
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setFieldErrors(newErrors);
      }
    },
  }

  useEffect(() => {
    setTermValue(term || 0)
  }, [term])

  return (
    <>
      {/* <Typography variant="subtitle2">
      Term {termValue || term} (in months)
    </Typography> */}
      {/* <Box style={{ padding: "0 7px" }}> */}
      <Box>
        {/* <Slider
        color="primary"
        size="small"
        marks={[
          { value: 12, label: 12 },
          // { value: 24, label: 24 },
          { value: 36, label: 36 },
          // { value: 48, label: 48 },
          { value: 60, label: 60 },
          // { value: 72, label: 72 },
          { value: 84, label: 84 },
        ]}
        step={12}
        min={12}
        max={84}
        value={termValue || term || 60}
        onChange={(event) => setTermValue(event.target.value)}
        onChangeCommitted={(event) => handleTerm(termValue)}
        defaultValue={60}
        aria-label="Default"
        valueLabelDisplay="auto"
      /> */}

        <TextField
          fullWidth
          id="term"
          label="Terms (in months)"
          variant="filled"
          type="text"
          size="small"
          name="term"
          error={fieldErrors?.term}
          helperText={fieldErrors?.term}
          value={termValue}
          onChange={(event) => handle.onChangeField(event?.target?.value, "term")}
          onBlur={(event) => handle.blurFn("term", +event?.target?.value)}
        />
      </Box>
    </>
  )
}

export default Term 