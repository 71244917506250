export default function getDeviceDetails() {
  const userAgent = navigator?.userAgent,
    platform = navigator?.userAgentData?.platform || navigator?.platform,
    macosPlatforms = ["macOS", "Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"];
  const language = navigator?.language;
  const screenWidth = window.innerWidth; // Screen width
  const screenHeight = window.innerHeight; // Screen height

  let os = null;
  let deviceType = "Desktop";
  let browser = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (/Linux/.test(platform)) {
    os = "Linux";
  }

  if (/Mobi|Android/i.test(userAgent)) {
    deviceType = "Mobile"; // Mobile devices
  } else if (/iPad|Android|Silk/i.test(userAgent)) {
    deviceType = "Tablet"; // Tablet devices
  }

  const isLandscape = window.matchMedia("(orientation: landscape)").matches;
  const deviceOrientation = isLandscape ? "Landscape" : "Portrait";

  // List of known browsers and their identifiers in the userAgent string
  if (userAgent.includes("Chrome") && userAgent.includes("Safari") && !userAgent.includes("Edge")) {
    browser = "Chrome";
  } else if (userAgent.includes("Firefox")) {
    browser = "Firefox";
  } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
    browser = "Safari";
  } else if (userAgent.includes("Edge")) {
    browser = "Edge";
  } else if (userAgent.includes("Trident")) {
    // For Internet Explorer
    browser = "Internet Explorer";
  } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
    browser = "Opera";
  } else if (userAgent.includes("MSIE")) {
    // For older IE versions
    browser = "Internet Explorer";
  }

  // Get GPU
  const canvas = document.createElement("canvas");
  const gl = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
  const debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
  const gpu = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) ?? "N/A";

  const deviceDetails = {
    userAgent,
    language,
    os,
    deviceType,
    browser,
    screenWidth,
    screenHeight,
    deviceOrientation,
    gpu,
  };

  return deviceDetails;
}