import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { ERRORS_MESSAGE, SUCCESS_MESSAGE, cellAlign, inVoiceHeader } from "../../../constants";

import { getInVoiceList, userSelector, getInVoiceDownloadUrl } from "../../../store/slices/userSlice";
import { TableLoader, TableNotFound } from "../../../components/customComponents/TableLoader";

const InVoiceDetail = () => {
  const dispatch = useDispatch();
  const { inVoiceListLoader, inVoiceList, inVoiceDetailLoader, inVoiceDetail, url } = useSelector(userSelector);
  const [expand] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(
      getInVoiceList({
        cursor: "1",
      }),
    );
  }, []);

  // const handleDetailView = (name, id) => {
  //   dispatch(getInVoiceDetail({ serviceId: id }));
  //   setExpand({
  //     [id]: !expand[id],
  //   });
  // };

  const handleDownloadInVoice = (id) => {
    dispatch(getInVoiceDownloadUrl({ serviceId: id })).then((res) => {
      if (res?.payload?.data?.invoice_pdf) {
        enqueueSnackbar(SUCCESS_MESSAGE.generatePdf, {
          variant: "success",
          autoHideDuration: 5000,
        });
      } else {
        enqueueSnackbar(ERRORS_MESSAGE.fetchErrorMsg, {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    });
  };

  return (
    <Grid container spacing={1.5} direction={"column"} width={"100%"}>
      <Grid item style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        
      </Grid>
      <Grid item style={{ display: "flex", justifyContent: "center" }}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {inVoiceHeader?.map((header) => (
                  <TableCell align={cellAlign} style={{ fontWeight: "bold" }} key={header}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {inVoiceListLoader && <TableLoader columnSpan={7} />}
              {!inVoiceList || (inVoiceList?.docs?.length === 0 && !inVoiceListLoader) ? (
                <TableNotFound columnSpan={7} />
              ) : (
                inVoiceList?.docs?.map((row) => (
                  <>
                    <TableRow
                      key={row._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align={cellAlign}>{moment(row?.createdA).format("DD-MM-YYYY")}</TableCell>
                      <TableCell component="th" scope="row" align={cellAlign}>
                        {row?.user?.lastName
                          ? `${row?.user?.firstName} ${row?.user?.lastName}`
                          : `${row?.user?.firstName}`}
                      </TableCell>
                      <TableCell align={cellAlign}>
                        {row?.year ? moment(new Date(row?.year, row?.month, 1)).format("MMM-YYYY") : "-"}
                      </TableCell>
                      <TableCell align={cellAlign}>${Number(row?.total / 100).toFixed(2)}</TableCell>
                      <TableCell align={cellAlign}>{row?.status}</TableCell>
                      {/* <TableCell align={"right"}>
                        {expand[row?._id] ? (
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              setExpand({
                                [row._id]: !expand[row?._id],
                              });
                            }}
                          >
                            Hide
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              handleDetailView(row?.name, row?._id);
                            }}
                          >
                            View
                          </Button>
                        )}
                      </TableCell> */}
                      <TableCell align={"center"}>
                        {row?.invoice_pdf_url && (
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => handleDownloadInVoice(row?._id)}
                            href={url}
                          >
                            Download
                          </Button>
                        )}
                      </TableCell>
                      <TableCell align={"center"}>
                        {row?.status === "open" && row?.hosted_invoice_url && (
                          <Button variant="outlined" size="small" href={row?.hosted_invoice_url} target="_blank">
                            Pay
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                    {expand[row._id] && inVoiceDetailLoader && <TableLoader columnSpan={7} />}
                    {expand[row._id] && inVoiceDetail?.length === 0 && !inVoiceDetailLoader ? (
                      <TableNotFound columnSpan={7} />
                    ) : (
                      expand[row._id] && (
                        <>
                          {inVoiceDetail?.length !== 0 && (
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell align="left">
                                {" "}
                                <Typography fontWeight={"bold"} fontSize={"0.75rem"}>
                                  Services name
                                </Typography>
                              </TableCell>
                              <TableCell colSpan={5}></TableCell>
                            </TableRow>
                          )}
                          {inVoiceDetail?.map((serviceDetail) =>
                            serviceDetail?.service_usage_ids?.map((service) => (
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="left">
                                  <Typography fontSize={"0.75rem"}>{service?.services?.name}</Typography>
                                </TableCell>

                                <TableCell></TableCell>
                                <TableCell align={cellAlign}>
                                  <Typography fontSize={"0.75rem"}>
                                    {`$${Number(service?.services?.fee / 100).toFixed(2)}`}
                                  </Typography>
                                </TableCell>
                                <TableCell colSpan={3}></TableCell>
                              </TableRow>
                            )),
                          )}
                        </>
                      )
                    )}
                  </>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default InVoiceDetail;
