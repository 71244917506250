import { useCallback, useState } from "react";
// import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Typography } from "@mui/material";
import * as Yup from "yup";
import { debounce } from "lodash";
// import { handleMixpanelQuoteEvent } from "../../../utils/mixPanelTrackEvents";
import { customersSelector, saveEmployer } from "../../../store/slices/applicationFormSlice";
import regex from "../../../utils/regex";


const LengthOfEmployment = () => {
  const dispatch = useDispatch();
  // const { applicationId } = useParams();
  const customers = useSelector(customersSelector);
  // const [timeOfEmployment, setTimeOfEmployment] = useState(customers?.[0]?.employers[0]?.timeOfEmployment && customers?.[0]?.employers[0]?.timeOfEmployment);

  const [allValues, setAllValue] = useState({
    timeOfEmployment: customers?.[0]?.employers[0]?.timeOfEmployment && customers?.[0]?.employers[0]?.timeOfEmployment,
  });

  const [fieldErrors, setFieldErrors] = useState({
    timeOfEmployment: "",
  });

  // function handleUpdateEmployment(value) {
  //   handleMixpanelQuoteEvent(applicationId, "length of employment", value)
  //   // dispatch(saveEmployer({ timeOfEmployment: value }))
  //   dispatch(
  //     saveEmployer({
  //       employerId: customers[0]?.employers[0]?._id,
  //       customer: customers[0]?._id,
  //       timeOfEmployment: value
  //     })
  //   );
  // };

  const validationSchema = Yup.object({
    timeOfEmployment: Yup.string()
      .optional()
      .matches(regex.timeOfEmploymentRegex, {
        message: "Employment length must be up to 1200 months",
      })
      .max(4, "Maximum of 4 digits"),
  });

  const debouncedValidation = useCallback(
    debounce(async (name, value) => {
      try {
        await validationSchema.validateAt(name, { [name]: value });
        setFieldErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      } catch (error) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [name]: error.message,
        }));
      }
    }, 300),
    []
  );

  const handle = {
    onChangeField: async (e, fieldName) => {
      setAllValue({ ...allValues, [fieldName]: e });
      debouncedValidation(fieldName, e);
    },
    commonEventHandle: (value, name) => {
      if (+value > 0 && +value <= 1200 && +customers[0]?.employers[0]?.[name] !== value) {
        dispatch(
          saveEmployer({
            [name]: value,
            employerId: customers[0]?.employers[0]?._id,
            customer: customers[0]?._id,
          }),
        );
      }
    },
  };

  return <>
    {/* <Typography variant="subtitle2">Time at current job {timeOfEmployment === 25 ? "24+" : timeOfEmployment} (mths)</Typography>
    <Box style={{ padding: "0 7px" }}>
      <Slider
        color="primary"
        size="small"
        marks={[
          { value: 0, label: 0 },
          { value: 6, label: 6 },
          { value: 12, label: 12 },
          { value: 18, label: 18 },
          { value: 24, label: 24 },
          { value: 30, label: "30+" },
        ]}
        step={1}
        min={0}
        max={30}
        value={timeOfEmployment}
        onChange={(event) => {
          setTimeOfEmployment(event.target.value)
          // updateQuote(event.target.value);
        }}
        onChangeCommitted={() => {
          handleUpdateEmployment(timeOfEmployment)
          // updateQuote(event.target.value);
        }}
        aria-label="Default"
        valueLabelDisplay="auto"
      />
    </Box> */}
    <Typography variant="caption">
      Length of employment (in months)
    </Typography>
    <TextField
      fullWidth
      id="timeOfEmployment"
      label="Length of employment"
      variant="filled"
      type="text"
      size="small"
      name="timeOfEmployment"
      value={allValues?.timeOfEmployment}
      error={fieldErrors?.timeOfEmployment}
      helperText={fieldErrors?.timeOfEmployment}
      onChange={(event) => handle.onChangeField(event?.target?.value, 'timeOfEmployment')}
      onBlur={(event) => handle.commonEventHandle(+event?.target?.value, "timeOfEmployment")}
    />
  </>
}

export default LengthOfEmployment